
.interviewQ-head{
    padding: 20px;
 background-color: #2784fc;
 color: #ffffff;
 height: 40px;
}
.interviewQ-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.q-box{
    width: 70%;
    text-align: right;
    padding: 10px;
    margin-bottom: 20px;
    box-shadow: 0 0 7px #afafaf;
    border-radius: 6px;
    line-height: 1.5;
    direction: rtl;
   
}
.q-box p{
    color: #343434;
}