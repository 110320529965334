#guest-cont{
padding: 20px;
display: flex;
flex-direction: column;
align-items: center;
 
}
#guest-opts{
    width: 300px;
    height: 80px;
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

}