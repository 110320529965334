

#slider{
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2;
   width: 100%;
box-shadow: 0px 0px 7px #0000003d;     
background-color: #131313c4;
animation: moveup 0.3s;
animation-timing-function: ease-in;
   direction: ltr;
   padding:10px;
}
@keyframes moveup {
  from {bottom: -300px;}
  to {bottom: 0;}
}
#cvs{
 display: flex;
 flex-direction: column;
align-items: center;

}
.innerslide{
  transition: 0.5s;

  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
-ms-overflow-style: none;  /* IE 10+ */
}
.innerslide::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Chrome/Safari/Webkit */
}


#slideController{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  
}
#slideController button{
   border-radius: 50%;
   width: 50px;
}
.img-cont{
  width: 200px;
  height: 300px;  
  padding: 10px;
}
.img-cont img{
  width: 100%; 
  height: 100%;
  border-radius: 5px;
  box-shadow: 0px 0px 7px #0000003d;     
  cursor: pointer;
  transition: 0.4s;
}
.img-cont button{
  background: none;
  padding: 0;
  border: none;
  outline: none;
  width: 200px;
  height: 100%;
}



.textfield{
    text-align: right;
    direction: rtl;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 220px;
    padding: 10px;
}
.textfield input {
    padding:5px;
    border: 1px solid #dce5f0;
    border-radius: 4px;
    text-align: right;
    direction: rtl;
    outline:none;
    background-color: #f1f5fa;
    font-size: 15px;
    width: 100%;
  }
  .textfield input:focus{
    border: 0;
  }
  label{
    margin-bottom: 10px;
    color: #747474;
    text-align: center;
  }


  .more{
    background: none;
    border: none;
    outline: none;
    color: #2784fc;
    align-self: flex-start;
  }
  .more:hover{
    background: none;
    border: none;
    outline: none;
    color: #2784fc;
  }
  @keyframes example {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  

  button{
     width: 100%;
     border: none;
     border-radius: 4px;
     outline: none;
     background-color: #2784fc;
     display: flex;
     flex-direction: row;
     justify-content: space-evenly;
     align-items: center;
     padding: 5px;
     color: #ffffff;
     font-size: 14px;
     direction: rtl;
  }
  button:hover{
    background-color:#64a0e9 ;
    transition: 0.3s;
  }
.provider{
  background-color: white;
  color: #131313;
  box-shadow: 0 0 3px #151515;

}
  .previewBtn,.continue,.submit,.backBtn,.finishBtn{
    background-color: #ff9500;
  }
  .previewBtn:hover,.continue:hover,.submit:hover,.backBtn:hover,.finishBtn:hover{
    background-color: #faa42b;
  }
 
.close{
  background-color: #3a3a3a;
  font-size: 25px;
}
.close:hover{
  background-color: #4d4d4d;
}
.link-outlined-btn{
  background: none;
  width: 100%;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: #131313;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  direction: rtl;
}
.link-outlined-btn:hover{
   color: #454545;
   transition: 0.3s;
}
  .delete{
    color: #ff0000;
    border: 1px solid #b9b8b8;
    outline-color: #ff0000;
    background-color: #ffffff;
}
.delete:hover{
   color: #ff0000;
   background-color: #f5f5f5;
}
.link-btn{
     width: 100%;
     border: none;
     outline: none;
     text-decoration: none;
     display: flex;
     flex-direction: row;
     justify-content: space-evenly;
     align-items: center;
     text-decoration: none;
     color: #ffffff;
     padding: 8px;
     border-radius: 4px;
     font-size: 14px;
     direction: rtl;
  }
  .link-btn:hover{
    background-color:#cae2ff ;
    transition: 0.5s;
  }
  @media (max-width: 450px) {

    #slideController button{
    display: none;
   }

}

 