
.App {
  text-align: center;
  font-family: 'Almarai', sans-serif;

}
.container{
  max-width: 1440px;
  /* min-height: 400px; */
   font-family: 'Almarai', sans-serif;
   
}

body{
  width: 100%;
  background-color: #f6f7f9;
  font-family: 'Almarai', sans-serif;

}
hr{
  width: 100%;
  border-top: 1px solid #8a95a7;
  border-bottom: none;
    border-right: none;
    border-left: none;
}
button{
  cursor: pointer;
  font-family: 'Almarai', sans-serif;
  line-height: 1.5;
}
select,input{
  font-family: 'Almarai', sans-serif;
  height: 20px;
  line-height: 1.5;
  font-size: 15px;
}

#suspense-loader{
  height: 100%;
  background-color: #f7f7f7;
}




/* 
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
