/* $orange: #ff9500;
$blue: #2784fc; */
header{
    padding: 0 20px;
    background-color: #2784fc;
    color: #ffffff;
    direction: rtl;
    display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   height: 70px;
   box-shadow: 0px 0px 7px #a4b6c9;

}

nav{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 40%;
}

header img {
  height: 30px;
  width: 96px;

  }

  header a{
    margin-left: 10px;
   text-decoration: none;
   color: #ffffff;
   padding: 5px;

}

header a:hover{
    color:#c5defc ;
    /* border-bottom: 1px solid #ff9500; */
    transition: 0.5s;
 }

 header a:active{
    color: #ffffff;
    transition: 0.3s;
 }
 header .MuiCircularProgress-root{
     color: #ffffff;
 }
 .sign-up-link{
     border: 1px solid #ffffff;
     padding: 5px;
     /* box-shadow: 0px 0px 3px #ffffff; */
     border-radius: 3px;
     background-color: #ebf4ff;
     color: #006dfc;

 }
 .sign-up-link:hover{
  color: #006dfc;
  background-color: #ffffff;
  border: 1px solid #ffffff23;

}
.sign-up-link:active{
    background-color: #ffffff;
    transition: 0.3s;
  }
  .plansBtn:hover{
    background-color: #006dfc; 
  }
  .plansBtn{
    border: 1px solid #ffffff;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 90px;
    height: 23px;
}
.plansBtn img{
  width: 20px;
}
 #account-menu{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 11%;

 }

 #account-menu-icons{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
 }
 .settingOptionsMenu{
     direction: ltr;
     padding: 5px 5px;
     position: absolute;
     top: 70px;
     z-index: 1;
     background-color: #ffffff;
     display: flex;
     flex-direction: column;
     align-items: flex-end;
     border-radius: 0 0 3px 3px;
     box-shadow: 0px 0px 2px #181818af;

 }

 .settingOptionsMenu a{
     color:#2784fc ;
     display: flex;
     flex-direction: row;
     justify-content: flex-start;
     align-items: center;
     font-size: 15px;
 }
 .settingOptionsMenu a:hover{
    color:#7e8b9b ;
 
    
}

#mob-header{
  display: none;
}

@media (max-width: 1024px) {
 nav{
   width: 50%;
 }
}

@media (max-width: 820px) {
  #mob-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
 #burgerMenu{
   position: absolute;
   top: 0;
   left: 0;
   padding: 10px;
   background-color: #323232c2;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   height: 100%;
   width: 200px;
   animation: moveout 1s;
   animation-timing-function: ease-out;
 }
 @keyframes moveout {
  from {left: -300px;}
  to {left: 0px;}
}
 .openMenuBtn{
   background: none;
   border: none;
   outline: none;
   color: #ffffff;
 }
 #burgerMenu nav{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
#burgerMenu nav a{
  width: 100%;
  text-align: right;
}
#burgerMenu #account-menu{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
#burgerMenu #account-menu .settingOptionsMenu{
position: inherit;
background: none;
box-shadow: none;
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-end;
direction: rtl;
}
#burgerMenu #account-menu .settingOptionsMenu a{
  color: #ffffff;
  width: 95%;

  }
 
#burgerMenu .plansBtn {
  border: 1px solid #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  height: 23px;
}
#burgerMenu .plansBtn:hover {
 background:none;
}
#burgerMenu .plansBtn img {
  margin-left: 5px;
 }
  #header{
    display: none;
  }
}

 